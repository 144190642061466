import React from 'react'
import Nav from './Nav'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SocialIcons from './SocialIcons'

const StyledHeader = styled.header`
  height: 100px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.headerBgColor};
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2388addd' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(12, 13, 14, 0.3);

  @media (max-width: 760px) {
    height: 120px;
    flex-direction: column;
  }
`

const Logo = styled(Link)`
  font-size: 26px;
  line-height: 26px;
  font-weight: 300;
  color: #f5f7fa;
  align-self: center;

  @media (max-width: 760px) {
    display: none;
  }

  p {
    margin: 0;
    line-height: 26px;
  }

  h1 {
    font-size: 1.5rem;
  }
`

const MobileLogo = styled(Link)`
  h1 {
    margin: 0 0 6px 0;
    font-size: 20px;
    font-weight: 300;
  }

  color: #f5f7fa;
  align-self: center;

  @media (min-width: 761px) {
    display: none;
  }
`

export default function Header() {
  return (
    <StyledHeader>
      <Logo to="/">
        <h1>Steven Mercatante</h1>
      </Logo>
      <MobileLogo to="/">
        <h1>Steven Mercatante</h1>
      </MobileLogo>
      <Nav />
      <SocialIcons />
    </StyledHeader>
  )
}
