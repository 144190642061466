import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../atoms/Button'

const Container = styled.div`
  align-self: center;
  border: 1px solid #cfd4df;
  border-radius: 4px;
  padding: 40px;
  margin-top: ${props => props.marginTop + 'px'};
  margin-bottom: 40px;
  // background-color: ${props => props.theme.newsletterBgColor};
  background-color: #3E86E4;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23c0daf4' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E");

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`

const SubscribeButton = styled(Button)`
  background-color: #ee5253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  height: 34px;
  align-self: flex-end;
`

const Field = styled.div`
  width: 100%;
  margin: 0 20px 0 0;

  input {
    width: 100%;
    height: 34px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid rgb(222, 222, 222);
    box-sizing: border-box;
    padding: 0 10px;
  }

  label div {
    margin-bottom: 5px;
  }

  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
`

const Required = styled.span`
  color: #fe1a30;
`

export default function Newsletter({
  referrer,
  title,
  body,
  marginTop = 20,
  type = null,
  children,
}) {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const formAction = `https://stevenmercatante.us17.list-manage.com/subscribe/post?u=40787a75fabc75abcb181492f&amp;id=3119bb6a9d`

  if (type === 'video') {
    title = `Interested in more videos like this?`
    body = `Subscribe to be notified when I publish new videos.`
  } else {
    title = title || `Enjoyed This Article?`
    body = body || `Subscribe to be notified when I publish new content.`
  }

  return (
    <Container marginTop={marginTop}>
      <h4>{title}</h4>
      {children ? children : <p>{body}</p>}
      <Form
        action={formAction}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <Field>
          <label htmlFor="name">
            <div>First Name</div>
            <input
              type="text"
              value={firstName}
              name="FNAME"
              className=""
              id="mce-FNAME"
              onChange={e => setFirstName(e.target.value)}
            />
          </label>
        </Field>
        <Field>
          <label htmlFor="email">
            <div>
              Email <Required>*</Required>
            </div>
            <input
              type="email"
              value={email}
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              onChange={e => setEmail(e.target.value)}
            />
          </label>
        </Field>
        <SubscribeButton type="submit">SUBSCRIBE</SubscribeButton>
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="hidden"
            value={referrer}
            name="REF"
            id="mce-REF"
            tabIndex="-2"
            readOnly
          />
          <input
            type="text"
            name="b_40787a75fabc75abcb181492f_3119bb6a9d"
            tabIndex="-1"
            value=""
            readOnly
          />
        </div>
      </Form>
    </Container>
  )
}
