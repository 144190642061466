import React from 'react'
import SocialIcons from './SocialIcons'
import styled from 'styled-components'

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.footerBgColor};
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2388addd' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
  align-items: center;
  color: #f5f7fa;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`

export default () => (
  <Container>
    <p>Steven Mercatante © 2019</p>
  </Container>
)
