import React from 'react'
import styled from 'styled-components'

const Embed = styled.div`
  margin: 20px 0;
`

export default ({ src }) => {
  const embedUrl = src.endsWith('embed') ? src : `${src}/embed`

  return (
    <React.Fragment>
      <Embed>
        <div className="resp-container">
          <iframe
            className="resp-iframe"
            src={embedUrl}
            frameBorder="0"
            gesture="media"
            allow="encrypted-media"
            allowFullScreen
          />
        </div>
      </Embed>
      {/* <a href={extractEggheadUrl(src)}>
        View on Egghead.io for source code & transcript
      </a> */}
    </React.Fragment>
  )
}

const extractEggheadUrl = embedUrl => embedUrl.replace('/embed', '')
