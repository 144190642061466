import styled from 'styled-components'

const Button = styled.button`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border-style: none;
  padding: 0.5rem;
  cursor: pointer;
`

export default Button
