import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Codeblock from './Codeblock'
import styled, { ThemeProvider } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'

import EggheadEmbed from './EggheadEmbed'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
`

const Content = styled.div`
  max-width: 800px;
  padding: 40px;
  margin: 0 auto;

  @media (max-width: 650px) {
    padding: 10px;
    margin-top: 0.5rem;
  }
`

const greenTheme = {
  primaryColor: '#ff0000',
  newsletterBgColor: '#b4e0e1',
  headerBgColor: '#26B0A8',
  headerAccentColor: '#12726C',
  footerBgColor: '#26B0A8',
}

const blueTheme = {
  headerBgColor: '#3E86E4',
  headerAccentColor: '#08449C',
  footerBgColor: '#3E86E4',
  linkColor: '#EC5658',
  navLinkHoverColor: '#1F2933',
  newsletterBgColor: '#E3E6EB',
  primaryColor: '#4186E1',
  tagBgColor: '#3E4C58',
}

const components = {
  pre: props => <div {...props} />,
  code: props => (
    <Codeblock children={props.children.trim()} className={props.className} />
  ),

  EggheadEmbed,
}

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <ThemeProvider theme={blueTheme}>
        <MDXProvider components={components}>
          <Container>
            <Header />
            <ContentContainer>
              <Content>
                <div className="inner">{children}</div>
              </Content>
            </ContentContainer>
            <Footer />
          </Container>
        </MDXProvider>
      </ThemeProvider>
    )
  }
}

export default Layout
