import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledNav = styled.nav`
  align-self: center;
  font-size: 22px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;

  @media (max-width: 760px) {
    font-size: 20px;
  }

  @media (max-width: 375px) {
    font-size: 18px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }

  a {
    box-shadow: none;
    color: #f5f7fa;

    :hover {
      color: ${props => props.theme.navLinkHoverColor};
      text-shadow: none;
    }
  }
`

const Circle = styled.div`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #8bb6ee;
  margin: 10px;

  @media (max-width: 375px) {
    margin: 8px;
  }

  @media (max-width: 320px) {
    margin: 6px;
  }
`

export default function Nav() {
  return (
    <Wrapper>
      <StyledNav>
        <Link to="/about">About</Link>
        <Circle />
        <Link to="/articles">Articles</Link>
        <Circle />
        <Link to="/videos">Videos</Link>
        <Circle />
        <Link to="/work">Work</Link>
        <Circle />
        <Link to="/projects">Projects</Link>
        <Circle />
        <Link to="/contact">Contact</Link>
      </StyledNav>
    </Wrapper>
  )
}
