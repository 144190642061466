import React from 'react'
import twitterIcon from '../images/twitter.png'
import githubIcon from '../images/github.png'
import linkedInIcon from '../images/linkedin.png'
import styled from 'styled-components'

const Social = styled.div`
  align-self: center;

  a {
    padding: 0 10px;
    box-shadow: none;
  }
`

export default () => (
  <Social>
    <a href="https://twitter.com/mercatante">
      <img src={twitterIcon} alt="Twitter icon" />
    </a>
    <a href="https://github.com/steven-mercatante">
      <img src={githubIcon} alt="GitHub icon" />
    </a>
    <a href="https://www.linkedin.com/in/stevenmercatante/">
      <img src={linkedInIcon} alt="Linkedin icon" />
    </a>
  </Social>
)
